import * as React from "react"
import { Link } from "gatsby"
import Logo from "../svg/Logo"
import Seo from "../components/seo"

const Layout = ({ location, title, children }) => {
  const rootPath = `/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">
          <Logo />
        </Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        <Logo />
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <Seo title={title} />
      <header className="w-auto flex items-stretch top-0 shadow">
        <div className="flex items-left ml-6 py-4">{header}</div>
        <div className="flex items-right text-right pt-3">
          {/* <Link className="p-2 m-1" to="/projects">Projects</Link>
          <Link className="p-2 m-1" to="/contact">Contact Us</Link>
          <Link className="p-2 m-1" to="/about">About</Link> */}
        </div>
      </header>
      <main className="text-gray-100">{children}</main>
      <footer>
        <p className="p-8">
          © {new Date().getFullYear()} Team Zero |{" "}
          <a href="https://twitter.com/TeamZeroDev">@TeamZeroDev</a> |{" "}
          <a href="https://mesh.xyz/">ConsenSys Mesh</a>
        </p>
      </footer>
    </div>
  )
}

export default Layout
